<template>
  <div>
   
    <div v-if="ActiveTab">
      
    <div v-if="ActiveSection && !SingleFormEditing" style="padding-top: 15px;padding-bottom:150px;">
      <SingleTabFieldsSection v-if="ActiveSection.DataViewName === 'Data Single Fields' && ActiveSection.ShowComponents"
      @UpdateEditableField="UpdateEditableField" @GetShortLivedURL="GetShortLivedURL" @UpdateRecordCalculation="UpdateRecordCalculation"
            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" :SingleElementView="SingleElementView"
            @OpenDialog="OpenDialog" :sectionindex="ActiveSectionIndex" :tabindex="ActiveTabIndex" @onPhotoUpload="onPhotoUpload" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
            :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateProcessing="ActivateProcessing"
            :Record="Record" :AdditionalSaveMethod="AdditionalSaveMethod" :System="System"
            :section="ActiveSection" :tab="ActiveTab" :Fields="ActiveSection.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="CanEdit"
            />
      <DataSingleImageGallery  v-if="ActiveSection.MultipleFileUploadType === 'Gallery' && ActiveSection.ShowComponents || 
      ActiveSection.MultipleFileUploadType === 'Images' && ActiveSection.ShowComponents || ActiveSection.DataViewName === 'Data Single Gallery' && ActiveSection.ShowComponents"
      :EntityName="EntityName" :EntityCollectionRef="ConfigDB"  :BaseStorageRef="BaseStorageRef" :EntityDataRef="ItemOperationalDB"
        @UpdateEditableField="UpdateEditableField" :System="System"
      :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
      @OpenDialog="OpenDialog" :sectionindex="ActiveSectionIndex" :tabindex="ActiveTabIndex"
      :PrimaryFieldName="PrimaryFieldName" :SingleFormEditing="SingleFormEditing" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
      :Record="Record" :AdditionalSaveMethod="AdditionalSaveMethod"
      :section="ActiveSection" :tab="ActiveTab" :Fields="ActiveSection.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="ActiveSection.CanEdit" :CanCreate="ActiveSection.CanCreate" :CanDelete="ActiveSection.CanDelete"
      />
      <DataSingleDocumentRegister  v-if="ActiveSection.MultipleFileUploadType === 'Document Register' && ActiveSection.ShowComponents"
      :EntityName="EntityName" :BaseStorageRef="BaseStorageRef"
      :Record="Record" :System="System"
      :SystemEntities="SystemEntities" :SingleFormEditing="SingleFormEditing" :EntityCollectionRef="ConfigDB" :EntityDataRef="ItemOperationalDB"
      @OpenDialog="OpenDialog" :sectionindex="ActiveSectionIndex" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
      :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
      :AppointmentsQuery="AppointmentsQuery" :ActivitiesArray="ActivitiesArray" :CanGet="true"
      :section="ActiveSection" :tab="ActiveTab" :Fields="ActiveSection.Fields" :AppisDarkMode="AppisDarkMode" :CanEdit="ActiveSection.CanEdit" :CanCreate="ActiveSection.CanCreate" :CanDelete="ActiveSection.CanDelete"
      />
      <TableEmbedTab v-if="ActiveSection.MultipleFileUploadType === 'Related Table' && ActiveSection.Entityid && ActiveSection.ShowComponents"
          :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	 :System="System"								
          :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="ActiveTabIndex" :rowindex="rowindex" :CurrentEntity="CurrentEntity"		
          :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="ConfigDB" :EntityDataRef="ItemOperationalDB"					
          :tab="ActiveSection" @OpenDialog="OpenDialog" :ParentTab="ActiveTab" :DynamicWikiData="DynamicRecordData"									
          />
        <TableEmbedTab v-if="ActiveSection.MultipleFileUploadType === 'SubCollection' && ActiveSection.Entityid && ActiveSection.ShowComponents"
        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	 :System="System"							
        :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="ActiveTabIndex" :rowindex="rowindex" :CurrentEntity="CurrentEntity"	
        :SystemEntities="SystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="ConfigDB" :EntityDataRef="ItemOperationalDB"					
        :tab="ActiveSection" @OpenDialog="OpenDialog" :ParentTab="ActiveTab" :DynamicWikiData="DynamicRecordData" :RADB="RADB"									
        />
    </div>
    <div v-if="SingleFormEditing">
      <NewLandingPage :DataSingleTabs="ComputedSingleBuildTabs" :TableRecordView="true"
    :SinglePageBGGradient="SinglePageBGGradient" :SystemPhotoLibraries="SystemPhotoLibraries"
    :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"
    :SingleRecordNavMenu="SingleRecordNavMenu" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
    @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
    @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
    :EntityDataRef="ItemOperationalDB" :FeatureNavList="FeatureNavList"
    :EntityCollectionRef="ConfigDB" :CurrentEntity="CurrentEntity"
    :System="System" :SystemEntities="ConfigSystemEntities"  :AdditionalSaveMethod="''"
        @UpdateEditableField="UpdateEditableField" :SingleFormEditing="SingleFormEditing"
        :AppisDarkMode="AppisDarkMode" :CanEdit="Record.CanEdit" :tab="tab"
        :DynamicWikiData="DynamicRecordData" :Business_Unit="Business_Unit"
        :RelatedObj="RelatedObj" :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
        :ActivitiesArray="ActivitiesArray" @CancelEditing="CancelEditing"
        :PrimaryFieldName="PrimaryFieldName"
    :ActiveEntityID="ConfigEntityID"/>
    </div>
    </div>
    <v-bottom-navigation dark :background-color="ActiveSectionType.Color"
     :style="PaginationRequired ? 'bottom: 50px;position: absolute;' : 'bottom: -5px;position: absolute;box-shadow: none;'">
    <v-btn :small="MobileView" @click="ActivateActiveTab(sect)" v-for="sect in SectionSelection"  :key="sect.itemObjKey">
      <span>{{sect.DisplayName ? sect.DisplayName : sect.Name}}</span>
          <v-icon small>{{sect.Icon}}</v-icon>
    </v-btn>
  </v-bottom-navigation>
  <v-bottom-navigation :background-color="ActiveSectionType.Color" v-if="PaginationRequired"
    style="bottom: -5px;position: absolute;box-shadow: none;">
      <v-pagination 
      v-model="page"
      :length="PaginationLength"
    ></v-pagination>
  </v-bottom-navigation>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';
import DataSingleDocumentRegister from '@/components/Database/DataSingleDocumentRegister';
import DataSingleImageGallery from '@/components/Database/DataSingleImageGallery';
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';
export default {
    props: ['System','SystemEntities','CurrentEntity','Record','RADB','ItemOperationalDB','ConfigDB','SystemPhotoLibraries','SystemGuides','RAApp',
    'ActiveSectionType','ActiveTab','ActiveTabIndex','ActiveSectionIndex','ActiveSection'],
    components: {
        NewLandingPage,
        LandingPageSingle,
        SingleTabFieldsSection,
        DataSingleDocumentRegister,
        DataSingleImageGallery,        
        TableEmbedTab,
        },
    data() {
        return {        
          page: 1,  
          SectionTypes: [
            {Name: 'Fields',TypeProps: ['Data Single Fields'],Icon: 'mdi-clipboard-list',Color: 'blue'},
            {Name: 'Galleries',TypeProps: ['Data Single Gallery'],Icon: 'mdi-folder-image',Color: 'pink'},
            {Name: 'Documents',TypeProps: ['Data Single Document Register'],Icon: 'mdi-database',Color: 'brown'},
            {Name: 'Related',TypeProps: ['Data Single Related Data'],Icon: 'mdi-database',Color: 'brown'}
          ],
            BannerTabs: [
        ],
            CustomRolesDefault:   
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'UpdatePermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
            CanEdit: false,
            CanDelete: false,
            ConfigSystemEntities: [],
            SingleFormEditing: false,
          LiaisonDialogActive: false,
          RecordObj: '',
          RecordData: '',
          SingleBuildTabs: [],
          SubCollections: []
        }
    },	
    computed:{
      UpdatedSearchQuery(){
      let SearchQuery = []
      if(this.CurrentEntity && this.CurrentEntity.AllFields){
        this.CurrentEntity.AllFields.map(fieldobj => {
          let value = this.RecordData[fieldobj.id]
          if(value){
            if(fieldobj.IsSearch || fieldobj.Primary){
              let PrimaryString = ''
                if(fieldobj.Type === 'Single Line Text'){
                  PrimaryString = value.trim()
                }
                else if(fieldobj.Type === 'Option Set'){
                  PrimaryString = value.Name.trim()
                }
              var lowertext = "";       
              var p;
              let buildup = ''
              //console.log(PrimaryString.length,PrimaryString)
              for (p = 0; p < PrimaryString.length; p++) {
                  buildup = buildup+ PrimaryString[p]
                  if(p === PrimaryString.length-1){
                    lowertext += buildup.toLowerCase();
                  }
                  else{
                    lowertext += buildup.toLowerCase() + ",";
                  }
              } 
              SearchQuery = SearchQuery.concat(value.trim().split(' '))         
              SearchQuery = SearchQuery.concat(lowertext.split(','))  
              SearchQuery = SearchQuery.map(entry => {
                return entry.toLowerCase()
              }) 
            }
          }          
        })
      }
      return SearchQuery      
    },
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      
      ComputedSingleBuildSections(){
        return this.ComputedSingleBuildTabs.map((tab,tabindex) => {
          return tab.Elements.map((elmnt,elmntindex) => {
            let elmntobj = Object.assign({},elmnt)
            elmntobj.TabObj = tab
            elmntobj.TabIndex = tabindex
            elmntobj.ElmntIndex = elmntindex
            return elmntobj
          })
        }).flat()
      },
      
      SectionSelection(){
        return this.PaginationRequired ? this.PaginatedSectionsbyType : this.ComputedSingleBuildSectionsbyType
      },
      PaginatedSectionsbyType(){
        let start = (this.page-1)*this.PaginationSize
        let end = start+this.PaginationSize
        //console.log(start,end)
        return this.ComputedSingleBuildSectionsbyType.slice(start,end)
      },
      PaginationLength(){
        return Math.ceil(this.ComputedSingleBuildSectionsbyType.length/this.PaginationSize)
      },
      PaginationRequired(){
        return this.ComputedSingleBuildSectionsbyType.length > this.PaginationSize
      },
      PaginationSize(){
        if(this.$vuetify.breakpoint.xs){
          return 3
        }
        else if(this.$vuetify.breakpoint.sm){
          return 4
        }
        else if(this.$vuetify.breakpoint.md){
          return 5
        }
        else if(this.$vuetify.breakpoint.lg){
          return 6
        }
        else if(this.$vuetify.breakpoint.xl){
          return 8
        }
      },
      ComputedSingleBuildSectionsbyType(){
        return this.ComputedSingleBuildSections.filter(sect => {
          return this.ActiveSectionType ? this.ActiveSectionType.TypeProps.includes(sect.DataViewName) : sect
        })
      },
      PrimaryFieldName(){
        return this.CurrentEntity.Primary_Field_Name
      },
      EntityName(){
            return this.CurrentEntity.DisplayName
        },
      BaseStorageRef(){
            return this.EntityName.split(' ').join('')+'/'
        },
      FieldTabs(){
        let fieldtabs = this.ComputedSingleBuildTabs.filter(tab => {
          return tab.DataViewName === 'Data Single Standard'
        }).map(tab => {
          return tab.Elements
        }).flat()
        return fieldtabs.filter(tab => {
          return tab.DataViewName === 'Data Single Fields'
        })
      },
        createAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createAll']
    },
    createUnitdown(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createUnitdown']
    },
    createUserUnit(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createUserUnit']
    },
    createOwner(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'createOwner']
    },
    deleteAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
    },
    deleteAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
    },
    deleteUnitdown(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteUnitdown']
    },
    deleteUserUnit(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteUserUnit']
    },
    deleteOwner(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteOwner']
    },
    deleteAll(){
    return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'deleteAll']
    },
    editAll(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editAll']
    },
    editUnitdown(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editUnitdown']
    },
    editUserUnit(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editUserUnit']
    },
    editOwner(){
      return this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'editOwner']
    },
    userBUID () {
    return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    ImageHeight(){
    return window.innerHeight
    },
    ImageWidth(){
      return window.innerWidth
    },
    StatusToggleName(){
    return this.Status && this.Status.ID === 1000001 ? 'Deactive' : 'Activate'
  },
        ComputedSingleBuildTabs(){
      let AllFields = this.CurrentEntity.AllFields
      return this.SingleBuildTabs.map(tab => {
        let elementsprop = ''
        if(tab.Elements){
          elementsprop = 'Elements'
        }
        else{
          elementsprop = 'Sections'
        }
        //alert(elementsprop)
        if(tab[elementsprop]){
          tab[elementsprop] = tab[elementsprop].map(section => {
            //console.log(section)
          if(section.Fields){
            section.Fields = section.Fields.map(field => {
             
              let fieldobj = AllFields.find(obj => obj.id === field.Name)
              //console.log('AllFields,field.Name',AllFields,field.Name,fieldobj)
              if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID !== 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup',fieldobj.RelatedRecord.split(' ').join('')+'Array',this.$store.state[fieldobj.RelatedRecord+'Array'],this.$store.state)
                  fieldobj.Array = this.$store.state[fieldobj.RelatedRecord.split(' ').join('')+'Array']
                  if(fieldobj.IsMultiple){
                    fieldobj.Array = fieldobj.Array.map(item => {                      
                            let itemobj = {
                            [fieldobj.LookupFieldName]: item[fieldobj.LookupFieldName],
                            id: item.id
                            }
                            return itemobj
                        })
                    }
                }
                else if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID === 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup','Users',this.$store.state['Users'],this.$store.state)
                  fieldobj.Array = this.$store.state['Users']
                }
                if(!fieldobj){
                  console.log('missing field ',field)
                  fieldobj = field
                }
              return fieldobj
            })
          }
          //prepare updated permissionname Props just prior to GetCabinetDocuments
          //CustomRolesDefault
          //console.log(section.Name,section.DataViewName,section,this.$store.state)
          if(section.DataViewName === 'Data Single Document Register' || section.DataViewName === 'Data Single Gallery'){
          //  this.CustomRolesDefault.map(defrole => {
          //    console.log(defrole.Prop,section.Name,section[defrole.Prop])
          //  })
            if(section.CreatePermission === 'Inherited'){
              section.CanCreate = this.CanEdit
            }
            else if(section.CreatePermission === 'Custom Role'){
              section.CustomCreateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanCreate = true
                }
              })
            }
            else if(section.CreatePermission === 'Admin'){
              section.CanCreate = this.userIsAdmin
            }
            if(section.GetPermission === 'Inherited'){
              section.CanGet = true
            }
            else if(section.GetPermission === 'Custom Role'){
              section.CustomGetRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanGet = true
                }
              })
            }
            else if(section.GetPermission === 'Admin'){
              section.CanGet = this.userIsAdmin
            }
            if(section.ListPermission === 'Inherited'){
              section.CanList = true
            }
            else if(section.ListPermission === 'Custom Role'){
              section.CustomListRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanList = true
                }
              })
            }
            else if(section.ListPermission === 'Admin'){
              section.CanList = this.userIsAdmin
            }
            if(section.UpdatePermission === 'Inherited'){
              section.CanEdit = this.CanEdit
            }
            else if(section.UpdatePermission === 'Custom Role'){
              section.CustomUpdateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanEdit = true
                }
              })
            }
            else if(section.UpdatePermission === 'Admin'){
              section.CanEdit = this.userIsAdmin
            }
            if(section.DeletePermission === 'Inherited'){
              if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Custom Roles'){
                section.CanDelete = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'CanDelete']
              }
              else if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
               if(this.deleteAll){
                  section.CanCreate =  true
                }
                else if(this.deleteUnitdown){
                   if(this.userBUChildren.includes(this.DynamicRecordData.Business_Unitid)){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteUserUnit){
                  if(this.DynamicRecordData.Business_Unitid === this.userBUID){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteOwner){
                  if(this.DynamicRecordData.Ownerid === this.UserRecord.id){
                    section.CanCreate = true  
                    }
                }
              }
            }
            else if(section.DeletePermission === 'Custom Role'){
              section.CustomDeleteRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanDelete = true
                }
              })
            }
            else if(section.DeletePermission === 'Admin'){
              section.CanDelete = this.userIsAdmin
            }
          }
          else if(section.DataViewName === 'Data Single SubCollection'){
            let subcol = this.SubCollections.find(obj => obj.id === section.Entityid)
            console.log(section,subcol)
            section.CanCreate = this.CanEdit
            section.CanDelete = this.CanDelete
            section.CanEdit = this.CanEdit

          }
          // if(this.SelectedElement && this.SelectedElement.DisplayName !== section.DisplayName){
          //   section.ShowComponents = false
          // }
          return section
        })
        // .filter(elmnt => {
        //   return elmnt.ShowComponents
        // })
        }
         //tab.ShowComponents = false
        return tab
      }).filter(tab => {
        return tab.Elements.length > 0
      })
      
    },
    RelatedDataTabs(){
      if(this.ComputedSingleBuildTabs){
       return this.ComputedSingleBuildTabs.map(tab => {
        return tab.Elements
      }).flat()
      .filter(elmnt => {
        return elmnt.DataViewName === 'Data Single Related Data'
      }) 
      }
      else {
        return []
      }
      
    },
    DynamicRecordData(){
      let vm = this
      let dataobj = {}
      //this should become OpsRecordID not routeparams, not work along with subcol entry view
      dataobj.id = this.$route.params.id
      if(!this.$route.params.id){
        //because from tablemain view
        dataobj.id = this.RecordObj.id
      }
      //console.log('this.ConfigSystemEntities',this.ConfigSystemEntities)
      let entity = this.ConfigSystemEntities.find(obj => obj.id === this.ConfigEntityID)
      //console.log('entity',entity)
      
      if(entity){
      if(entity.SubCollections){
        dataobj.SubCollections = this.SubCollections
        this.SubCollections.map(subcol => {
          if(subcol.id === 'BookedUsers'){
            ///console.log('subcol',subcol)
            dataobj.BookedUsers = subcol.Data.map(bookeduser => {
              let usermatch = this.UsersArray.find(obj => obj.id === bookeduser.id)              
              //console.log('usermatch',usermatch)
              let userobj = Object.assign({},usermatch)
              userobj.BookingObj = Object.assign({},bookeduser)
              if(bookeduser.BookingDate){
               userobj.BookingDate = bookeduser.BookingDate 
              }
              else if(bookeduser.Created_On){
                userobj.BookingDate = bookeduser.Created_On 
              }              
              return userobj
            })
          }
        })
      }
      if(this.RelatedDataTabs){
        //this.EntityReady = false
        dataobj.RelatedTables = this.RecordObj.RelatedTables
        //this.EntityReady = true
        //console.log('dataobj.RelatedTables',dataobj.RelatedTables)
      }
      //console.log('dataobj.RelatedTables',dataobj.RelatedTables,this.RelatedDataTabs,this.RecordObj.RelatedTables)
      entity.AllFields.map(field => {
        //console.log('field.Name ',field.Name,this[field.Name])
        if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){
          field.FieldBreakdown.map(breakdown => {
            dataobj[breakdown.Name] = this.RecordData[breakdown.Name]
          })
        }
        else if(field.Type === 'Date' && this.RecordData[field.Name] && this.RecordData[field.Name].toDate){
          let dateobject = this.RecordData[field.Name].toDate()
          dataobj[field.Name] = format(dateobject,'yyyy-MM-dd')
          if(field.HasTimeInput){
            let hours = dateobject.getHours()
            let mins = dateobject.getMinutes()
            if(hours === 0){
              hours = '00'
            }
            if(mins === 0){
              mins = '00'
            }
            field.TimeResponse = hours+':'+mins
            dataobj[field.Name+'TimeResponse'] = field.TimeResponse
          }
        }
        else{
          if(this[field.Name]){
            dataobj[field.Name] = this[field.Name]
          }
          else if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]           
            if(field.Type === 'Option Set' && field.HasDeepHierarchy){
               let primopt = field.Options.find(obj => obj.ID === this.RecordData[field.Name].ID)
              if(primopt && field.Levels && field.Levels[1] && this.RecordData[field.Levels[1].FieldName]){   
                dataobj[field.Levels[1].FieldName] = this.RecordData[field.Levels[1].FieldName] 
                let secopt = primopt.Options.find(obj => obj.ID === this.RecordData[field.Levels[1].FieldName].ID)          
                if(secopt && field.Levels && field.Levels[2] && this.RecordData[field.Levels[2].FieldName]){
                  dataobj[field.Levels[2].FieldName] = this.RecordData[field.Levels[2].FieldName]
                  dataobj[field.Levels[2].FieldName].Options = secopt.Options
                  //console.log(field)
                }
              }
            }
          }
          
        }
        // if(this[field.Name]){
        //  dataobj[field.Name] = this[field.Name] 
        // }        
      })
      if(this.FeatureNavList){
        this.FeatureNavList.FeatureList.map(field => {
          if(typeof this.RecordData[field.Name] !== 'undefined'){
            dataobj[field.Name] = this.RecordData[field.Name]
          }
        })
      }
      
      //console.log('dataobj',entity,this.SubCollections,dataobj)
      if(this.RecordObj.Order_Progress){
        dataobj.Order_Progress = this.RecordObj.Order_Progress
      }
      return dataobj    
      }
      
    },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        NeedsRASync(){
          return this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.TableBuilder || 
                this.$store.state.NeedsRASync && this.SystemConfigorRA && this.tab.Entityid === 'entities'         
        },
        TabsRenderMode(){
        if(!this.SingleFormEditing && !this.RelatedTabsRefresh){
            return 'Standard Tabs'
        }
        else if(this.SingleFormEditing){
            return 'Editing Tabs'
        }
        },
        ConfigEntityID(){
      if(this.$route.meta && this.$route.meta.SubColEntryViewer){
        let parententity = this.SystemEntities.find(obj => obj.id === this.$route.params.slug.split(' ').join('_'))
        let subcol = parententity.SubCollections.find(obj => obj.id === this.$route.params.id)
        //console.log(parententity,subcol)
        if(subcol.EntityBuilder){
          return this.$route.query.Record
        }
        else{
          return this.$route.query.Parent
        }
      }
      else if(this.$route.name === 'PDFExportFunctionBuilder'){
        return this.FunctionEntityid
      }
      // else{
      //   return this.$route.meta && this.$route.meta.SubColEntryViewer ? this.$route.query.Parent : this.$route.meta.EntityID
      // } 
      else if(this.$route.query && this.$route.query.TableType === 'System_Entity'){
        return this.$route.query.TableEntity
      }
      else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && !this.$route.query.TableEntity){
        return this.$route.params.id
      }
      else if(this.$route.query && this.$route.query.TableType === 'Operational_Entity' && this.$route.query.TableEntity){
        return this.$route.query.TableEntity
      }
      else{
        return this.$route.meta.EntityID
      }
    },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
      AppQuickDashPlugin(){
        return this.ActiveSuiteApp.Plugins.find(obj => obj.id === 'Quick_Dashboards')
      },
      CalendarTable(){
        return this.CurrentEntity.Entity_Type.Name === 'Calendar'
      },
      CalendarTypeEntity(){
        return this.ScheduleBookingEntity || this.CalendarTable
      },
      ScheduleBookingEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Booking Schedule'
      },
      StoreArticleEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Inventory Article'
      },
      StoreProductEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Product'
      },
      StoreOrderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'Store Order'
      },
      QuickEditEntity(){
        return this.StoreArticleEntity || this.StoreProductEntity || this.CalendarTypeEntity || this.SystemBuilderEntity
      },
      SystemBuilderEntity(){
        return this.CurrentEntity.Entity_Type.Name === 'System Builder'
      },  
      QuickEditSections(){
        if(this.StoreArticleEntity){
          let sect1fields = ['Product_Code','Product_Group','Item_Type','UOM','PrimaryCategoryField','Descriptive_Text']
          let sect2fields = ['Image','Purchase_Leadtime','Supply_Leadtime','Last_Quoted_Price','Last_Quoted_Price_Date','Store_Price']
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Type: 'Gallery',GalleryName: 'Gallery',Class: 'flex xl12 lg12 md12 sm12 xs12',SelectAction: 'Set Primary',CanCreate: this.editedItem && this.editedItem.CanEdit},
          ]
        }
        else if(this.SystemBuilderEntity){
          let sect1fields = ['Name','BillingAccount','Business_Unit']
          let sect2fields = [{id: 'Suite_Logo',Name: 'Suite_Logo',DisplayName: 'Suite Logo',text: 'Suite Logo',value: 'Suite_Logo',
          Type: 'Single File Upload',FeaturedPhoto: true}]
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields).concat([{Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description'}])
            ,Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Details',Type: 'Fields',Fields: sect2fields,Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Plugins',Type: 'SubCollection',GalleryName: 'BookedUsers',Class: 'flex xl12 lg12 md12 sm12 xs12',SubCollection: {}
            ,SelectAction: 'Set Primary',
            SubCollectionComponent: this.ActiveComponent('components/SuiteBuilder/Main/RASuiteSubscriptions'),}
          ]
        }
        else if(this.StoreProductEntity){
          let sect1fields = ['Item_Name','Inventory_Article','Cost_Price','Markup','Discount','Store_Price']
          let sect2fields = ['On_Order','Backorder','Inbound','Received','On_Hand']
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Basic',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Movement',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            //{Type: 'Gallery',GalleryName: 'Gallery',Class: 'flex xl12 lg12 md12 sm12 xs12',SelectAction: 'Set Primary'},
          ]
        }
        else if(this.ScheduleBookingEntity){
          let sect1fields = ['Title','Type','Owner','Presenter','Description']
          let sect2fields = ['Image']
          let sect3fields = ['User_Limit','RSVP_Amount','RSVP_Type','RSVP_Advance_Amount','RSVP_Advance_Type']
          let bookingsubcol = this.CurrentEntity.SubCollections[0]
          let bookedusers = this.editedItem && this.editedItem.BookedUsers ? this.editedItem.BookedUsers.map(booking => {
            let userobj = this.UsersArray.find(obj => obj.id === booking.id)
            userobj.BookingObj = booking
            return userobj
          }) : []
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Header',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Graphics',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect2fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
            {Header: 'Booking Settings',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect3fields),Class: 'flex xl12 lg12 md6 sm12 xs12',
             Tooltip: this.RSVPUserLimit+`<br>`+this.RSVPSoonestTooltip+`<br>`+this.RSVPLatestTooltip
            },
            {Header: 'Bookings',Type: 'SubCollection',GalleryName: 'BookedUsers',Class: 'flex xl12 lg12 md12 sm12 xs12',SubCollection: bookingsubcol,SelectAction: 'Set Primary',
            SubCollectionComponent: this.ActiveComponent('components/General/UserLookupTable'),
            UsersLookupArray: bookedusers,UserHeaders: bookingsubcol.Headers,AdditionalHeaders: []}
          ]
        }
        else if(this.CalendarTable){
          //tricky but essentially 'Owner' applies if bu structure entity
          let sect1fields = ['Title','Description']
          //no image it would seem?
          //let sect2fields = ['Image']
          //let sect3fields = ['User_Limit','RSVP_Amount','RSVP_Type','RSVP_Advance_Amount','RSVP_Advance_Type']
          //HAPPPY THAT NOBODY CAN BOOK ON CALENDAR RIGHT? ALSO WHAT ABOUT CUSTOM SUBCOLS? QUICKEDIT?
          //<!-- store supplier. and perhaps plugin based being if no warehouse inbound no purch LT, not price, no last quoted price, although may be useful either way? -->
          return [
            {Header: 'Header',Type: 'Fields',Fields: this.PrepQuickFieldSection(sect1fields),Class: 'flex xl6 lg6 md6 sm12 xs12'},
          ]
        }
        
        else{
          return []
        }
      },
      MyActivitiesArray(){
      return []
    },
    ActivitiesArray(){
      return []
    },
    AppointmentsArray(){
      return []
    },
      ComputedActivityType(){
        if(this.ActivityType){
          return this.ActivityType
        }
        else{
          return {
            countname: 'None',
            tasks: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id}),
            apointments: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id}),
          }
        }
      },
        RelatedObj(){
        return this.Record && this.Record.id ? 
        {id: this.Record.id, [this.CurrentEntity.Primary_Field_Name]: this.Record[this.CurrentEntity.Primary_Field_Name],identifier: this.CurrentEntity.Primary_Field_Name,
        collection: this.CurrentEntity.id.split('_').join('').toLowerCase(),Link: this.CurrentEntity.SingleName.split(' ').join('') }
        : ''
      },
      ActiveSuiteid(){
        return this.$store.state.ActiveSuiteid
      },
      ItemOperationalDB(){
        return this.Record && this.Record.id ? 
          //db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id)
          this.$store.state.NewTableStructure && !this.$store.state.roottableentities.includes(this.CurrentEntity.id) && !this.$store.state.rootdbentities.includes(this.CurrentEntity.id) ? 
          db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.Record.id) : 
          this.$store.state.roottableentities.includes(this.CurrentEntity.id) ?
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id) : 
          db.collection('Databases').doc(this.ActiveSuiteid).collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id) : ''
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        CurrentEntityFields(){
        return this.CurrentEntity.AllFields.map(field => {
          let fieldobj = Object.assign({},field)
          return fieldobj
        })
      },
    },
    watch: {
      Record:{
         handler: function(newvalue, oldvalue) {
          if(oldvalue !== newvalue){
            this.PrepRecord()
          }
         },deep: true
      },
      ComputedSingleBuildTabs:{
         handler: function(newvalue, oldvalue) {
          if(oldvalue !== newvalue){
            this.$store.commit('SetCurrentDataTabs',this.ComputedSingleBuildTabs)
          }
          },
          deep: true
      },
        RouteObj: {
            handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                  
                  //console.log(oldvalue, newvalue)
                  this.$emit('ActivateRecordLoadingDialog',true,0)
                  this.SingleRecordNavMenu = false
                  this.EntityReady = false
                  this.ProcessStages = []
                  this.ActiveProcess = ''
                  this.ActiveProcessDocid = ''
                  this.StageID = 1
                  this.StageName = ''
                  this.CurrentEntity = ''
                  this.ConfigSystemEntities = []  
                  this.CanEdit = false  
                  //alert('routechange CanEdit '+this.CanEdit)              
                  this.CheckRouting()
                  this.GetSystemRecord()
                  this.PrepareSuiteApp()
                }
            },
            deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.PrepRecord()
        this.PrepRecordInteraction()      
    },
    methods:{
      PrepRecord(){
        this.RecordObj = Object.assign({},this.Record)
        this.RecordData = Object.assign({},this.Record)
      },
      PrepRecordInteraction(){
        this.CanEdit = this.Record.CanEdit
        this.CanDelete = this.Record.CanDelete
        this.ConfigSystemEntities = this.SystemEntities        
        this.SubCollections = []
        this.CurrentEntity.AllFields.map(field => {
          if(field.Type === 'Date' && this.RecordData[field.Name] && this.RecordData[field.Name].toDate){
            let dateobject = this.RecordData[field.Name].toDate()
            this.Record[field.Name] = format(dateobject,'yyyy-MM-dd')
            if(field.HasTimeInput){
              let hours = dateobject.getHours()
              let mins = dateobject.getMinutes()
              if(hours === 0){
                hours = '00'
              }
              if(mins === 0){
                mins = '00'
              }
              let TimeResponse = hours+':'+mins
              this.Record[field.Name+'TimeResponse'] = TimeResponse
            }
          }
        })
        console.log(this.Record)
              this.CurrentEntity.SubCollections.map(subcol => {
                let subcolheaders = []
                subcol.Headers.map(header => {
                  let headerobj = { text: header.text, value: header.value, Type: header.Type }
                  if(headerobj.Type === 'Option Set'){
                    headerobj.Options = header.Options
                    headerobj.propvalue = header.value
                    headerobj.value = headerobj.value+'.Name'
                  }
                  if(header.Primary){
                    headerobj.Primary = header.Primary
                  } 
                  if(header.CommonFieldType){
                    headerobj.CommonFieldType = header.CommonFieldType
                  }
                  if(header.IsContentEditable){
                    headerobj.IsContentEditable = header.IsContentEditable
                  }
                  if(header.FieldFormulas){
                    headerobj.FieldFormulas = header.FieldFormulas
                  }
                  subcolheaders.push(headerobj)
                })
                subcolheaders.push({ text: 'Linked From', value: 'LookupObj'})
                subcolheaders.push({ text: 'Action', value: 'action'})         
                let subcolobj = {
                  id: subcol.id,
                  Name: subcol.id,
                  Data: [],
                  Headers: subcolheaders,
                  Type: subcol.Type
                }
                if(subcol.Type === 'Lookup' && subcol.LookupBuilds){
                  //console.log('subcolobj',subcol,subcolobj)
                  subcolobj.LookupBuilds = subcol.LookupBuilds.map(build => {
                  let newbuildobj = {id: build.id, identifier: build.recordprimaryfield.Name,recordprimaryfield: build.recordprimaryfield}
                  let lookupbuild = this.ConfigSystemEntities.find(obj => obj.id === build.id)
                  //console.log('subcol obj',lookupbuild,this.ConfigSystemEntities,build.id)
                  if(lookupbuild){
                    newbuildobj.AllFields = lookupbuild.AllFields
                  }
                  
                   if(build.LinkedFields){
                    newbuildobj.LinkedFields = build.LinkedFields
                  }
                  if(build.single){
                    newbuildobj.single = build.single
                  }
                  if(build.SubCollections){
                    newbuildobj.SubCollections = build.SubCollections
                  }
                  //newbuildobj.recordprimaryfield = build.recordprimaryfield
                  if(build.Entity_Type){
                    newbuildobj.Entity_Type = build.Entity_Type
                    if(build.Entity_Type.Name === 'Store Product'){ 
                      let buildapp = this.AppsDataBase.find(obj => obj.id === lookupbuild.Primary_Appid)                     
                      let articletablefield = lookupbuild.AllFields.find(obj => obj.id === 'Inventory_Article')
                      if(articletablefield){
                        newbuildobj.ArticleTableField = articletablefield
                      }
                      if(buildapp && buildapp.Plugins.find(obj => obj.id === 'Warehouse_Outbound')){
                        newbuildobj.Bypass_Picking_and_Packing = true
                      }
                      if(typeof subcol.Available_Stock_Only !== 'undefined'){
                        newbuildobj.Available_Stock_Only = subcol.Available_Stock_Only
                      }
                    }
                  }
                  if(lookupbuild.Security_Structure){
                    newbuildobj.Security_Structure = lookupbuild.Security_Structure
                  }
                  //console.log('newbuildobj',newbuildobj)
                  // Bypass_Receiving
                  return newbuildobj
                  })
                }
                if(subcol.ConfigValueasDocID){
                  subcolobj.ConfigValueasDocID = subcol.ConfigValueasDocID
                  subcolobj.DocIDProp = subcol.DocIDProp
                }
                if(subcol.Lookup_Once_Only){
                  subcolobj.Lookup_Once_Only = subcol.Lookup_Once_Only
                }
                if(subcol.PrimaryHeader){
                  subcolobj.PrimaryHeader = subcol.PrimaryHeader
                }
                if(subcol.HasSingleView){
                  subcolobj.HasSingleView = subcol.HasSingleView
                }
                 if(subcol.EntityBuilder){
                  subcolobj.EntityBuilder = subcol.EntityBuilder
                }
                 if(subcol.HeadersFromSubCol){
                  subcolobj.HeadersFromSubCol = subcol.HeadersFromSubCol
                  subcolobj.HeadersSubCollection = subcol.HeadersSubCollection
                }
                this.SubCollections.push(subcolobj)
                this.SubCollections.map(newsubcol => {
                  newsubcol.CanCreate = this.CanEdit
                  newsubcol.CanEdit = this.CanEdit
                  newsubcol.CanDelete = this.CanDelete
                  this.GetSubCollectionData(newsubcol.id,newsubcol.Data,newsubcol.LookupBuilds)
                  
                })
                //console.log('this.SubCollections',this.SubCollections)
              })
        // this.SubCollections.map(newsubcol => {
        //     newsubcol.CanCreate = this.CanEdit
        //     newsubcol.CanEdit = this.CanEdit
        //     newsubcol.CanDelete = this.CanDelete
        //     newsubcol.Data = []
        //     this.GetSubCollectionData(newsubcol.id,newsubcol.Data,newsubcol.LookupBuilds)
            
        // })
        this.SingleBuildTabs = this.CurrentEntity.SingleBuildTabs.map(tabobj => {
                let tab = Object.assign({},tabobj)
              if(tab.Elements){
                tab.Elements.map(section => {
                  if(section.DataViewName === 'Data Single Activities' || section.DataViewName === 'Data Single Fields'){
                    if(!section.Name){
                      section.Name = section.DisplayName
                    }
                    if(section.Name){
                     let bannertab = {
                        Name: section.Name.split(' ').join(''),
                        }    
                        if(section.DataViewName === 'Data Single Fields' && !bannertab.Icon){
                          bannertab.Icon = 'mdi-database'
                        }  
                        else if(section.DataViewName === 'Data Single Activities' && !bannertab.Icon){
                          bannertab.Icon = 'mdi-clipboard-list'
                        }            
                      let banneron = this.BannerTabs.find(obj => obj.Name === bannertab.Name)
                      if(!banneron){
                        this.BannerTabs.push(bannertab)
                      } 
                    }
                    
                  }                    
                })
              }
              
              if(tab.Type === 'Standard'){
                //alert(typeof 12/tab.Sections.length)
                let sectionlength = tab.Sections.length
                //alert(typeof sectionlength)
                tab.Sections = tab.Sections.map(section => {
                  section.Cols = 12/sectionlength
                  section.ColsMD = section.Cols
                 
                  if(sectionlength > 1){
                    section.ColsSM = 6
                  }
                  else{
                    section.ColsSM = 12
                  }
                   if(!section.FlexXLRG){
                    section.FlexXLRG = section.ColsMD
                    section.FlexLarge = section.ColsMD
                    section.FlexMedium = section.ColsMD
                    section.FlexSmall = section.ColsSM
                    section.FlexXSmall = 12
                  }
                  if(section.SectionType === 'Fields'){
                    section.Fields = section.Fields.map(field => {
                      if(field.Name && field.Name.Name){
                        return field.Name
                      }
                      else{
                        return field
                      }
                      
                    })
                  }
                  return section
                })
              }
              else if(tab.DataViewName === 'Data Single Cabinet'){
                if(tab.Elements){
                  tab.Elements.map(elmnt => {
                    if(elmnt.MultipleFileUploadType === 'Images' || elmnt.MultipleFileUploadType === 'Document Register' || elmnt.MultipleFileUploadType === 'Gallery'){
                      elmnt.DataArray = []
                      let cabinet = ''
                      if(elmnt.MultipleFileUploadType === 'Document Register'){
                        cabinet = this.CurrentEntity.DocumentRegisters.find(obj => obj.id === elmnt.Entityid)
                        if(cabinet && cabinet.PrimaryOptions){
                          elmnt.PrimaryOptions = cabinet.PrimaryOptions
                          elmnt.PrimaryOptionsInteger = cabinet.PrimaryOptionsInteger
                        }
                        if(!elmnt.PrimaryOptions){
                          elmnt.PrimaryOptions = []
                        }
                        if(!elmnt.PrimaryOptionsInteger){
                          elmnt.PrimaryOptionsInteger = 1000000
                        }
                      }
                      else if(elmnt.MultipleFileUploadType === 'Gallery'){
                        cabinet = this.CurrentEntity.Galleries.find(obj => obj.id === elmnt.Entityid)
                        
                      }
                      this.CustomRolesDefault.map(defrole => {
                        //console.log(defrole.Prop,cabinet[defrole.Prop],cabinet)
                          if(cabinet[defrole.Prop]){
                            elmnt[defrole.Prop] = cabinet[defrole.Prop]
                          }
                          if(cabinet[defrole.Name]){
                            elmnt[defrole.Name] = cabinet[defrole.Name]
                          }
                          if(!elmnt[defrole.Name]){
                            elmnt[defrole.Name] = []
                          }
                        })
                        //console.log(this.ItemOperationalDB)
                        //BIG MISTAKE RIGHT HERE YOU SEE???? GOTTA KNOW THE CABINET TYPE NOW!!!!!!! IS IT DOCREG OR GAL db.collection('db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records')
                      //this.GetCabinetDocuments(this.ItemOperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase()),elmnt)
                      if(elmnt.MultipleFileUploadType === 'Document Register'){
                        let dbref = this.$store.state.NewTableStructure && !this.roottableentities.includes(this.CurrentEntity.id) ? this.ItemOperationalDB.collection('DocumentRegisters').doc(elmnt.Name).collection('Items') : 
                        this.ItemOperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase())
                        this.GetCabinetDocuments(dbref,elmnt)
                      }
                      else if(elmnt.MultipleFileUploadType === 'Gallery'){
                        let dbref = this.$store.state.NewTableStructure && !this.roottableentities.includes(this.CurrentEntity.id) ? this.ItemOperationalDB.collection('Galleries').doc(elmnt.Name).collection('Items') : 
                        this.ItemOperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase())
                        this.GetCabinetDocuments(dbref,elmnt)
                      }
                    }
                  })
                }
              }
              return tab
            })
      },
      ActivateActiveTab(section){
        this.$emit('ActivateActiveTab',section)
      },
      ToggleSingleFormEditing(){
        this.SingleFormEditing = !this.SingleFormEditing
        //console.log(this.ComputedSingleBuildTabs)
      },
     
        GetSubCollectionData(subcolarrayname,subcoldata,LookupBuilds){
          //this.ItemOperationalDB.collection(subcolarrayname)
          //console.log(this.ItemOperationalDB.collection(subcolarrayname))
          //again now db.collection('Databases').doc(this.ActiveSuiteid).collection('entities').doc(this.CurrentEntity.id).collection('Records').doc(this.Record.id)
          let dbref = this.$store.state.NewTableStructure && !this.roottableentities.includes(this.CurrentEntity.id) ? this.ItemOperationalDB.collection('SubCollections').doc(subcolarrayname).collection('Items') : 
          this.ItemOperationalDB.collection(subcolarrayname)
      dbref.onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let subcolentry = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                subcolentry.CanCreate = this.CanEdit
                subcolentry.CanEdit = this.CanEdit
                subcolentry.CanDelete = this.CanDelete
               subcoldata.push(subcolentry)
                // if(LookupBuilds){
                //   buildswithsubcol = build.SubCollections.filter(build => {return build.SubCollections && build.SubCollections.length > 0})                    
                //     if(buildswithsubcol.length === 0){
                //       subcoldata.push(subcolentry)
                //     }
                //     else{
                //       buildswithsubcol.map(build => {
                //       if(build.SubCollections){
                //         build.SubCollections.map(subcolsubcol => {
                //           build.SubCollections = []
                //           this.GetSubColSubCol(build,subcolsubcol,this.ItemOperationalDB.collection(newsubcol.id).doc(subcolentry.id).collection(subcolsubcol))
                //         })                        
                //       }                      
                //     })
                //     }
                //   }
                //   else{
                //     subcoldata.push(subcolentry)
                //   }
              }
            })
      })
    },
        GetCabinetDocuments(query,elmnt){      
      query.onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let cabrecord = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = elmnt.DataArray.find(obj => obj.id === cabrecord.id)
                if(!oncheck){
                  elmnt.DataArray.push(cabrecord)
                }
              }
              if (change.type === 'modified') {
                let cabrecord = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = elmnt.DataArray.find(obj => obj.id === cabrecord.id)
                if(oncheck){
                  let index = elmnt.DataArray.indexOf(oncheck)
                  elmnt.DataArray.splice(index,1,cabrecord)
                }
              }
              if (change.type === 'removed') {
                let cabrecord = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = elmnt.DataArray.find(obj => obj.id === cabrecord.id)
                if(oncheck){
                    let index = elmnt.DataArray.indexOf(oncheck)
                    elmnt.DataArray.splice(index,1)
                  }
              }
            })
      })
    },
        ReduceQuickStep(){
            this.$emit('ReduceQuickStep')
        },
        PrepQuickFieldSection(fields){
        return fields.map(field => {
            let fieldobj = Object.assign({},this.CurrentEntityFields.find(obj => obj.id === field))
            if(fieldobj.Type === 'Date' && typeof fieldobj.DateMenu === 'undefined'){
              fieldobj.DateMenu = false
              if(typeof this.editedItem[fieldobj.Name] !== 'undefined' && typeof this.editedItem[fieldobj.Name].toDate !== 'undefined'){
                let dateobject = this.editedItem[fieldobj.Name].toDate()
                this.editedItem[fieldobj.Name] = format(dateobject,'yyyy-MM-dd')
              }
            }
            if(fieldobj.Type === 'Calculated Field'){
              this.editedItem[fieldobj.Name] = this.PerformedCalculation(this.editedItem,fieldobj)
            }
            return fieldobj
          })
      },
      
      SelectImage(tab, tapprop, asset, IsThis,SelectName){
        //console.log(tab, tapprop, asset, IsThis,SelectName)
        if(SelectName === 'Set Primary'){
          this.SelectPrimaryImage(asset)
        }
      },
      SelectPrimaryImage(asset){
        if(this.PrimaryImageFieldName){
          this.editedItem[this.PrimaryImageFieldName] = Object.assign({},asset)
          this.editedItem[this.PrimaryImageFieldName+'ThumbURL'] = asset.ThumbURL
        }
        this.quickeditstep = 1
        setTimeout(() => {
          this.quickeditstep = 2  
        }, 1);
              
        //console.log(this.editedItem)
      },
      AddGallerySubcolImages(event,item,subcolid){ 
      let fileList = event.target.files || e.dataTransfer.files
      this.$store.commit('setUploadFilesList',fileList)
      let sectionstorageref = subcolid.split(' ').join('')+'/'+item.id+'/'
      let basestorageref = this.TableNameSplit+'/'+item.id+'/'
      let filestorageref = basestorageref+sectionstorageref
      let dbrecordref = this.TableCollectionRef.doc(item.id).collection(subcolid.split(' ').join('').split('_').join('').toLowerCase())
      this.$store.commit('setUploadFileStorageRef',filestorageref)
      this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
      },
      UploadFileSelect(event,field,Record){
        let file = event.target.files[0]
        Record[field.Name] = {UploadFile: file, FileName: file.name,localURL: URL.createObjectURL(file)}
        this.quickeditstep = 1
        setTimeout(() => {
          this.quickeditstep = 2  
        }, 1);
        },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
        ActiveComponent(ComponentPath){
            return () => import(`@/`+ComponentPath+`.vue`);	 
        },
      ToggleLiaisonDialog(){
      this.LiaisonDialogActive = !this.LiaisonDialogActive
    },
    //COPIED OLD COMP METHODS
    
      GetShortLivedURL(item){
      //console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
         let payload = {
            item: item,
            result: result
          }
          this.$store.commit('SetSocialItemInteractMethodProp',payload) 
          this.$store.commit('SetSocialItemInteractMethod','ActivateViewFileDialog')
        })
    },
    CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
    onPhotoUpload(filename,file,storepath,field) {
    let vm = this
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$emit('ActivateProcessing',true)
         vm.PrepareThumbnail(filename,file,storepath).then(thumbresult => {
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              ThumbURL: thumbresult,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            }
            this.ItemOperationalDB.update({
                    [field.Name]: UploadObject,
                    [field.Name+'ThumbURL']: UploadObject.ThumbURL
            })
              vm.$emit('ActivateProcessing',false)
             let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Added '+field.DisplayName+' to - '+vm.RecordData[vm.CurrentEntity.Primary_Field_Name],
              snackbartop: true,
            }
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            delete field.localURL
            delete field.file
                })
         })
      },
    PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
                  type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
      UpdateRecordCalculation(Record,field,value){
      if(typeof value !== 'undefined'){
        Record[field.Name] = value
      }
        //console.log('UpdateRecordCalculation',Record,this.DynamicRecordData,field.Name,this.ItemOperationalDB)       
      },
      PrepareFieldupdateEmail(triggerfield,Record){
      let newrecord = Object.assign({},Record)
      let emailto = ''
      if(triggerfield.RecipientType === 'User'){
        let userobj = this.UsersArray.find(obj => obj.id === triggerfield.RecipientValue.id)
        emailto = userobj.Email
      }
      else if(triggerfield.RecipientType === 'Related User'){        
        let recordvalue = Record[triggerfield.RecipientValue.id]
        let userobj = this.UsersArray.find(obj => obj.id === recordvalue.id)
        emailto = userobj.Email
      }
      else if(triggerfield.RecipientType === 'Email Field'){
        let userfield = this.CurrentEntity.AllFields.find(obj => obj.id === triggerfield.RecipientValue.id)
        let recordvalue = Record[userfield.Name]
        emailto = recordvalue
      }
      else if(triggerfield.RecipientType === 'Static'){
        emailto = triggerfield.RecipientValue
      }
      if(emailto){
      let FormFields = triggerfield.EmailFormFields.map(item => {
        let fieldobj = this.CurrentEntity.AllFields.find(obj => obj.id === item)
        let newfieldobj = Object.assign({},fieldobj)
        if(fieldobj.Type === 'Lookup' || fieldobj.Type === 'Social Lookup'){
            newrecord[fieldobj.Name] = newrecord[fieldobj.Name][fieldobj.LookupFieldName]
        }
        else if(fieldobj.Type === 'Option Set'){[
            newrecord[fieldobj.Name] = newrecord[fieldobj.Name].Name
        ]}
        
        return newfieldobj
      })
      let EmailBody = `<html>
<body>
<head></head>
<h2>`+triggerfield.EmailSubject+`</h2>`+
`<p>`+triggerfield.EmailGreetingLine+`</p>
<table>`
             let length = FormFields.length
            FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.DisplayName+`:</th>
<td align="left">`+newrecord[field.Name]+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>`
                 } 
            })
          if(triggerfield.EmailincludesLinktoRecord){
          EmailBody = EmailBody+`<p>To view the details more details, click <a href="`+window.location.href+`">here</a></p>`  
          }
          if(triggerfield.EmailincludesUpdatingUserName){
          EmailBody = EmailBody+`<i>This update was made by `+this.userLoggedIn.Full_Name+` on `+new Date()+`</i>`  
          }
          
          EmailBody = EmailBody+`
</body>
</html>`  
            let EmailObject = {
                EmailFrom: 'noreply@businessandpeople.co.za',
                EmailTo: emailto,
                EmailSubject: triggerfield.EmailSubject,
                EmailBody: EmailBody
            }
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              this.EmailBody = ''
            })
      }
      else{
        //report to admin
      }
      
    },
    UpdateFeatureList(){
      if(this.FeatureNavList){
         this.FeatureNavList.FeatureList.map(feature => {
           let prop = feature.Name
           if(typeof this.DynamicRecordData[prop] !== 'undefined'){
             let value = this.DynamicRecordData[prop]
             db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).set({
              [prop] : value
            })
           }            
         })
      }
    },
      UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList){
        console.log(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList)
      let fieldobj = this.CurrentEntity.AllFields.find(obj => obj.id === prop)
      if(fieldobj && fieldobj.EmailonUpdate){
        this.PrepareFieldupdateEmail(fieldobj,FieldObject)
      }
      this.UpdateFeatureList()
      if(FromFeatureList && this.FeatureNavList.FeatureListType === 'Lookup' && typeof value !== 'undefined'){
        if(this.FeatureNavListLookupEntry){
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).update({
            [prop] : value
          })
        }
        else{
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Directories').doc(this.FeatureNavList.id).collection(this.FeatureNavList.FeatureListLookupField.RelatedBuildID).doc(this.RouteID).set({
            [prop] : value
          })
        }        
      }
      if(AdditionalSaveMethod && typeof value !== 'undefined'){
        this[AdditionalSaveMethod](prop,value,AdditionalSaveMethod,FieldObject)
      }
      else if(!AdditionalSaveMethod && typeof value !== 'undefined'){
        if(this.$route.meta && this.$route.meta.RecordisSystem && !this.SystemisRA){
          this.RAOperationalDB.update({
            [prop]: value
          }).then(radoc => {
            this.ItemOperationalDB.update({
              [prop]: value
            })
          })
        }
        else if(fieldobj && fieldobj.Type === 'Option Set' && fieldobj.HasDeepHierarchy){
              if(fieldobj.Levels && fieldobj.Levels[1] && FieldObject[fieldobj.Levels[1].FieldName]){                
                if(fieldobj.Levels && fieldobj.Levels[2] && FieldObject[fieldobj.Levels[2].FieldName]){
                  this.ItemOperationalDB.update({
                    [prop]: value,
                    [fieldobj.Levels[1].FieldName]: FieldObject[fieldobj.Levels[1].FieldName],
                    [fieldobj.Levels[2].FieldName]: FieldObject[fieldobj.Levels[2].FieldName]
                  })
                }
                else{
                  this.ItemOperationalDB.update({
                    [prop]: value,
                    [fieldobj.Levels[1].FieldName]: FieldObject[fieldobj.Levels[1].FieldName],
                  })
                }
              }
              else{
               this.ItemOperationalDB.update({
                    [prop]: value,
                  })
              }
            }
        else{
          this.ItemOperationalDB.update({
            [prop]: value
          })
           if(prop === 'Order_Total' && this.StoreProductEntity){
          let taxtotal = value*(this.$store.state.DefaultTax_Percentage/100)
          let grandtotal = value+taxtotal
           this.ItemOperationalDB.update({
            Grand_Total: grandtotal,
            Tax_Total: taxtotal,
            Tax_Percentage: this.$store.state.DefaultTax_Percentage
          })
        }
          if(FieldObject.IsSearch || FieldObject.Primary){
            let NameQuery = []
            let PrimaryString = ''
              if(FieldObject.Type === 'Single Line Text'){
                PrimaryString = value.trim()
              }
              else if(FieldObject.Type === 'Option Set'){
                PrimaryString = value.Name.trim()
              }
            var lowertext = "";       
            var p;
            let buildup = ''
            for (p = 0; p < PrimaryString.length; p++) {
                buildup = buildup+ PrimaryString[p]
                if(p === PrimaryString.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
            } 
            let SearchQuery = []
            if(this.RecordData.SearchQuery){

            }      
            NameQuery = lowertext.split(',') 
            NameQuery.length = NameQuery.length-1
            this.ItemOperationalDB.update({
              [prop+'Query']: NameQuery
            })
          }
        }
        this.UpdateRecordSearchQuery()
      }
    },
    UpdateRecordSearchQuery(){
      setTimeout(() => {
        this.ItemOperationalDB.update({
          SearchQuery: this.UpdatedSearchQuery
        })  
      }, 1000);      
    },
    }
}
</script>

<style>

</style>